<template>
  <div class="w-24">
    <SelectField2 v-model="selectedChartType" :options="availableChartTypes" :label="$t('chartType')"/>
  </div>
</template>

<script>
export default {
  name: 'ChartTypeSelect',
  computed: {
    selectedChartType: {
      get() {
        return this.$store.state.datacenter.chartType;
      },
      set(data) {
        this.$store.commit('setChartType', data);
        this.$store.commit('updateCurrentChartType');
      },
    },
    availableChartTypes() {
      const chartTypes = this.$store.state.datacenter.availableChartTypes;
      return chartTypes.map((t) => ({ text: this.$t(t.name), value: t.name }));
    },
  },
};
</script>
