<template>
  <div class="h-full text-white bg-gray-800 border-t-2 border-gray-800">
    <div v-if="!showFeaturesList">
      <div class="mb-2 ml-2">{{ $t('baseData') }}</div>
      <draggable
        id="availablePopulationsList"
        v-model="availablePopulationsList"
        group="populations"
        @end="resetBorders"
        @start="isDroppable.population = true"
        @ended="resetBorders"
        @abort="resetBorders"
        drag-class="dnd-item"
        ghost-class="dnd-item"
        class="min-height-drag"
      >
        <div
          class="p-1 mx-2 mb-2 text-gray-800 bg-white cursor-move "
          v-for="population in availablePopulationsList"
          :key="population.id"
        >
          <div class="mx-2">{{ $tc(population.name, 0) }}</div>
        </div>
      </draggable>
    </div>
    <div v-if="showFeaturesList">
      <div class="mb-2 ml-2 ">{{ $t('features') }}</div>
      <draggable
        id="availableFeaturesList"
        v-model="availableFeaturesList"
        group="features"
        :move="checkMove"
        @start="onStart"
        @end="resetBorders"
        drag-class="dnd-item"
        ghost-class="dnd-item"
        class="min-height-drag"
      >
        <div
          class="p-1 mx-2 mb-2 text-gray-800 bg-white cursor-move"
          v-for="feature in availableFeaturesList"
          :key="feature.id"
        >
          <div class="mx-2">{{ $tc(feature.name) }}</div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>

import helper from '@/mixins/dndHelper';

export default {
  mixins: [helper],
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch('init');
    });
  },
};

</script>

<style lang="scss" scoped>
  .dnd-item {
    background-color: theme('colors.primary.500');
  }

  .min-height-drag {
    min-height: calc(100vh - 80px - 80px - 120px);
  }
</style>
