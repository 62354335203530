<template>
  <div>
    <DataCenterView>
      <!-- Top bar -->
      <template slot="top-bar"></template>
      <!-- Sidebar -->
      <template slot="sidebar">
        <div class="flex h-full">
          <div class="w-1/2 p-1 pt-2 overflow-y-auto bg-gray-800">
            <Dragfield/>
          </div>
          <div class="w-1/2 pl-3 overflow-y-auto">
            <Dropfield/>
          </div>
        </div>
      </template>
      <!-- Content -->
      <template slot="content">
        <div class="flex flex-col w-full h-full">
          <div class="z-20 mb-3">
            <ChartConfigBar/>
          </div>
          <div class="flex flex-grow">
            <Chart v-if="!debugActive"/>
            <Debug v-else/>
          </div>
        </div>
      </template>
    </DataCenterView>
    <!-- Modal for sample chart data -->
    <EntryModal v-model="showModal" @confirm="onConfirm"/>
  </div>
</template>

<script>
import Dragfield from '@/ui/views/DataCenter/DataCenterChart/components/Dragfield.vue';
import Dropfield from '@/ui/views/DataCenter/DataCenterChart/components/Dropfield.vue';
import Chart from '@/ui/views/DataCenter/DataCenterChart/components/Chart.vue';
import ChartConfigBar from '@/ui/views/DataCenter/DataCenterChart/components/ChartConfigBar.vue';
import Debug from '@/ui/views/DataCenter/DataCenterChart/components/Debug.vue';
import EntryModal from '@/ui/views/DataCenter/DataCenterChart/components/EntryModal.vue';
import DataCenterView from '../components/DataCenterView.vue';

export default {
  components: {
    EntryModal,
    Debug,
    ChartConfigBar,
    Chart,
    Dropfield,
    Dragfield,
    DataCenterView,
  },
  data() {
    return {
      debugActive: false,
      showModal: !localStorage.getItem('hide_entry_modal'),
    };
  },
  methods: {
    toggleDebug() {
      this.debugActive = !this.debugActive;
    },
    onConfirm(loadSampleData) {
      if (loadSampleData) {
        this.$store.dispatch('loadSampleChartConfig');
      }
      this.showModal = false;
    },
  },
  mounted() {
    window.addEventListener('keyup', (evt) => {
      if (evt.getModifierState('OS')) {
        if (evt.key === '<') {
          this.toggleDebug();
        }
      }
    });
  },
};
</script>

<style scoped>

</style>
