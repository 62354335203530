<template>
  <div>
    <div class="text-xs font-bold">DEBUG MODE  (press Windows + &lt; to exit)</div>
        <div class="flex flex-row border border-black">
        <div class="flex flex-col flex-grow">
          <div class="text-xs border border-black">availablePopulationsList</div>
          <div class="h-full border border-black text-xxs"><pre> {{ availablePopulationsList }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">availableFeaturesList</div>
          <div class="h-full border border-black text-xxs "><pre> {{ availableFeaturesList }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">usedPopulationsList</div>
          <div class="h-full border border-black text-xxs "><pre> {{ usedPopulationsList }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">usedFeaturesList</div>
          <div class="h-full border border-black text-xxs "><pre> {{ usedFeaturesList }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">usedFiltersList</div>
          <div class="h-full border border-black text-xxs "><pre> {{ usedFiltersList }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">usedGroupsList</div>
          <div class="h-full border border-black text-xxs "><pre> {{ usedGroupsList }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">usedGroupValues</div>
          <div class="h-full border border-black text-xxs "><pre> {{ usedGroupValues }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">usedFilterValues</div>
          <div class="h-full border border-black text-xxs "><pre> {{ usedFilterValues }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">currentChartOptions</div>
          <div class="h-full border border-black text-xxs "><pre> {{ currentChartOptions }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">ChartConfig (Request)</div>
          <div class="border border-black text-xxs "><pre> {{ chartConfig }} </pre></div>
          <div class="text-xs border border-black">ChartOptions</div>
          <div class="h-full border border-black text-xxs "><pre> {{ chartOptions }} </pre></div>
        </div>
        <div class="flex flex-col flex-grow ">
          <div class="text-xs border border-black">Response</div>
          <div class="h-full border border-black text-xxs "><pre> {{ chartData }} </pre></div>
        </div>
      </div>
  </div>
</template>

<script>
import helper from '@/mixins/dndHelper';

export default {
  name: 'Debug',
  mixins: [helper],
  data() {
    return {
      testJson: {
        testA: 'test1',
        testB: 'test2',
      },
    };
  },
  computed: {
    chartConfig() {
      return this.$store.getters.chartConfig;
    },
    chartOptions() {
      return this.$store.state.datacenter.chartOptions;
    },
    chartData() {
      return this.$store.state.datacenter.chartData;
    },
    usedGroupValues() {
      return this.$store.state.datacenter.usedGroupValues;
    },
    usedFilterValues() {
      return this.$store.state.datacenter.usedFilterValues;
    },
    currentChartOptions() {
      return this.$store.state.datacenter.currentChartOptions;
    },
  },
  filters: {
    pretty(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
      // return JSON.stringify(value);
    },
  },

};
</script>

<style>

</style>
