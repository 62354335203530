<template>
  <div class="relative flex flex-col w-full p-3 bg-white">
    <div ref="chart" class="flex-grow" :class="{'opacity-50 pointer-events-none': isBusy}">
      <apexchart
        height="100%"
        :type="type"
        :options="chartOptions"
        :series="chartData"
      >
      </apexchart>
    </div>
      <loadingCircle class="absolute loading-circle right-1/2 top-1/2" v-show="isBusy"/>
  </div>
</template>

<script>
import LoadingCircle from '../../../../components/LoadingCircle.vue';

export default {
  name: 'Chart',
  components: { LoadingCircle },
  computed: {
    type() {
      return this.$store.state.datacenter.currentChartType;
    },
    chartOptions() {
      return this.$store.state.datacenter.currentChartOptions;
    },
    chartData() {
      return this.$store.state.datacenter.chartData;
    },
    isBusy() {
      return this.$store.state.datacenter.loadingState;
    },
  },
  beforeUpdate() {
    this.$store.commit('setChartWidth', this.$refs.chart?.clientWidth);
  },
};
</script>

<style>
.apexcharts-bar-area:hover {
  opacity: 0.6;
}

.apex-chart-xaxis-title {
  min-height: 50px;
}

.apexcharts-tooltip {
  border-radius: 0 !important;
  color: #434A4F;
}

.apexcharts-tooltip-title {
  background: #E3E4E5 !important;
}

.apexcharts-xaxistooltip {
  background: #E3E4E5 !important;
}

.apexcharts-tooltip-series-group {
  line-height: 1 !important;
  padding-bottom: 0 !important;
}

.loading-circle {
  z-index: 9999;
  transform: translate(50%, -50%);
}
</style>
