<template>
  <div class="flex px-2 py-3 bg-white xl:px-6">
    <DatePicker
      v-model="dateRange"
      :label="$t('time')"
      :presets="presets"
      :min="minDate"
      :max="maxDate"
      :disabled="hasPropertyFeature && !hasActionFeature"
      icon="chevron-down"
      period
      placeholder="TT.MM.JJJJ - TT.MM.JJJJ"
      year-select
      class="block mr-1 date-picker xl:mr-6"
      @dateValid="onDateValid"
    />
    <div class="mr-1 xl:mr-6">
      <IntervalSelect />
    </div>
    <div>
      <ChartTypeSelect />
    </div>
  </div>
</template>

<script>

import {
  subDays, startOfMonth, endOfMonth, getMonth, subMonths, getYear, startOfYear, endOfYear,
  subYears, format, addYears,
} from 'date-fns';

import ChartTypeSelect from './ChartTypeSelect.vue';
import IntervalSelect from './IntervalSelect.vue';

export default {
  name: 'ChartConfigBar',
  components: {
    IntervalSelect,
    ChartTypeSelect,
  },
  data() {
    return {
      presets: [],
    };
  },
  computed: {
    hasPropertyFeature() {
      return this.$store.getters.hasPropertyFeature;
    },
    hasActionFeature() {
      return this.$store.getters.hasActionFeature;
    },
    maxDate() {
      return format(addYears(new Date(), 20), 'yyyy-MM-dd');
    },
    minDate() {
      return format(subYears(new Date(), 100), 'yyyy-MM-dd');
    },
    dateRange: {
      get() {
        return [this.$store.state.datacenter.usedInterval.start, this.$store.state.datacenter.usedInterval.end];
      },
      set() {
        this.$store.commit('setIntervalStart', this.dateRange[0]);
        this.$store.commit('setIntervalEnd', this.dateRange[1]);
      },
    },
  },
  created() {
    this.buildPresets();
    this.$store.commit('setIntervalStart', this.getDefaultValue()[0]);
    this.$store.commit('setIntervalEnd', this.getDefaultValue()[1]);
  },
  methods: {
    onDateValid(value) {
      this.$store.commit('setIntervalStart', value[0]);
      this.$store.commit('setIntervalEnd', value[1]);
      if (this.hasActionFeature) {
        this.$store.dispatch('getActionChartData');
      }
    },
    /**
     * Get the last month as default value.
     */
    getDefaultValue() {
      const formatDate = (date) => format(date, 'yyyy-MM-dd');
      const today = new Date();
      const lastMonth = subMonths(today, 1);
      const lastMonthStartDate = startOfMonth(lastMonth);
      const lastMonthStartDateFormatted = formatDate(lastMonthStartDate);
      const lastMonthEndDate = endOfMonth(lastMonth);
      const lastMonthEndDateFormatted = formatDate(lastMonthEndDate);
      this.$store.commit('setIntervalStart', lastMonthStartDateFormatted);
      this.$store.commit('setIntervalEnd', lastMonthEndDateFormatted);
      return [lastMonthStartDateFormatted, lastMonthEndDateFormatted];
    },
    /**
     * Build the preset property that is passed to DatePicker.vue.
     * The preset property is used to build the selectable items above the date picker panel.
     */
    buildPresets() {
      const formatDate = (date) => format(date, 'yyyy-MM-dd');
      const today = new Date();
      // Calculate days
      const past7Days = formatDate(subDays(today, 7));
      const past30Days = formatDate(subDays(today, 30));
      const past365Days = formatDate(subDays(today, 365));
      // Calculate months
      const thisMonthIndex = getMonth(today);
      const thisMonthStartDate = formatDate(startOfMonth(today));
      const thisMonthEndDate = formatDate(endOfMonth(today));
      const thisMonthYear = getYear(today);
      const lastMonth = subMonths(today, 1);
      const lastMonthIndex = getMonth(lastMonth);
      const lastMonthStartDate = startOfMonth(lastMonth);
      const lastMonthStartDateFormatted = formatDate(lastMonthStartDate);
      const lastMonthEndDate = endOfMonth(lastMonth);
      const lastMonthEndDateFormatted = formatDate(lastMonthEndDate);
      const lastMonthYear = getYear(lastMonthStartDate);
      // Calculate years
      const thisYear = getYear(today);
      const thisYearStartDate = formatDate(startOfYear(today));
      const thisYearEndDate = formatDate(endOfYear(today));
      const lastYearDate = subYears(today, 1);
      const lastYear = getYear(lastYearDate);
      const lastYearStartDate = formatDate(startOfYear(lastYearDate));
      const lastYearEndDate = formatDate(endOfYear(lastYearDate));
      // Today formatted
      const todayFormatted = formatDate(today);
      this.presets = [
        {
          id: 1,
          sets: [
            {
              label: this.$t('pastNDays', { n: 7 }),
              startDate: past7Days,
              endDate: todayFormatted,
            },
            {
              label: this.$t('pastNDays', { n: 30 }),
              startDate: past30Days,
              endDate: todayFormatted,
            },
            {
              label: this.$t('pastNDays', { n: 365 }),
              startDate: past365Days,
              endDate: todayFormatted,
            },
          ],
        },
        {
          id: 2,
          sets: [
            {
              label: `${this.$t('months')[thisMonthIndex]} ${thisMonthYear}`,
              startDate: thisMonthStartDate,
              endDate: thisMonthEndDate,
            },
            {
              label: `${this.$t('months')[lastMonthIndex]} ${lastMonthYear}`,
              startDate: lastMonthStartDateFormatted,
              endDate: lastMonthEndDateFormatted,
            },
          ],
        },
        {
          id: 3,
          sets: [
            {
              label: thisYear,
              startDate: thisYearStartDate,
              endDate: thisYearEndDate,
            },
            {
              label: lastYear,
              startDate: lastYearStartDate,
              endDate: lastYearEndDate,
            },
          ],
        },
      ];
    },
  },
};
</script>

<style scoped>
.date-picker {
  width: 204px;
  margin-bottom: 0;
}
</style>
