export default {
  computed: {
    availablePopulationsList: {
      get() {
        return this.$store.state.datacenter.availablePopulations;
      },
      set(value) {
        this.$store.commit('updateAvailablePopulations', value);
      },
    },
    availableFeaturesList: {
      get() {
        if (this.$store.state.datacenter.usedPopulation[0]) { /* fixed index here because there is only one population possible */
          return this.$store.state.datacenter.usedPopulation[0].features.sort((a, b) => {
            const ta = this.$t(a.name).toLowerCase();
            const tb = this.$t(b.name).toLowerCase();

            if (ta === tb) {
              return 0;
            }
            return ta < tb ? -1 : 1;
          });
        }
        return null;
      },
      set(value) {
        this.$store.commit('updateAvailableFeatures', value);
      },
    },
    showFeaturesList() {
      if (this.$store.state.datacenter.usedPopulation[0]) { /* fixed index here because there is only one population possible */
        return true;
      }
      return false;
    },
    isDroppable: {
      get() {
        return this.$store.state.datacenter.isDroppable;
      },
      set(value) {
        this.$store.commit('updateIsDroppable', value);
      },
    },
    usedPopulationsList: {
      get() {
        return this.$store.state.datacenter.usedPopulation;
      },
      set(value) {
        this.$store.commit('updateUsedPopulation', value);
      },
    },
    usedFeaturesList: {
      get() {
        return this.$store.state.datacenter.usedFeatures;
      },
      set(value) {
        this.$store.commit('updateUsedFeatures', value);
      },
    },
    usedFiltersList: {
      get() {
        return this.$store.state.datacenter.usedFilters;
      },
      set(value) {
        this.$store.commit('updateUsedFilters', value);
      },
    },
    usedGroupsList: {
      get() {
        return this.$store.state.datacenter.usedGroups;
      },
      set(value) {
        this.$store.commit('updateUsedGroups', value);
      },
    },
    selectedInterval() {
      return this.$store.state.datacenter.interval.type;
    },
    usedFeatureIsProperty() {
      return this.usedFeaturesList.length > 0 && this.usedFeaturesList[0].type === 'property';
    },
  },
  methods: {
    resetBorders() {
      this.$store.dispatch('resetDropBorders');
    },
    /*
     *  onStart method is called on all drag-start-events and sets the isDroppable object corresponding to
     *  logical possible combinations.
     *
     *  TODO: revise logic according backend
     */
    onStart(evt) {
      if (evt.item._underlying_vm_.type === 'property') {
        if (this.usedFeaturesList.length === 0 || evt.item.parentElement.id === 'usedFeaturesList') {
          this.isDroppable.features = true;
        }
        if ((this.usedFiltersList.length < 2 || evt.item.parentElement.id === 'usedFiltersList')) {
          // && evt.item.parentElement.id !== 'usedGroupsList') {
          this.isDroppable.filters = true;
        }
        if ((this.usedGroupsList.length === 0 || evt.item.parentElement.id === 'usedGroupsList')
          && !this.$store.getters.hasPropertyFeature) { // Grouping for property charts disabled.
          this.isDroppable.groups = true;
        }
      }
      if (evt.item._underlying_vm_.type === 'action') {
        if (this.usedFeaturesList[0] && this.usedFeaturesList[0].type === 'property') {
          this.isDroppable.features = false;
        } else if (this.usedFeaturesList.length < 2 || evt.item.parentElement.id === 'usedFeaturesList') {
          this.isDroppable.features = true;
        } else {
          this.isDroppable.features = false;
        }
      }
    },
    /*
     *  checkMove callback method is triggered when a dragged item hovers over a draggable container
     *  and disables dropping according to the isDroppable object
     */
    checkMove(evt) {
      if (evt.to.id === 'availableFeaturesList') {
        return false;
      }
      if (evt.to.id === 'usedFeaturesList') {
        return this.isDroppable.features;
      }
      if (evt.to.id === 'usedFiltersList') {
        return this.isDroppable.filters;
      }
      if (evt.to.id === 'usedGroupsList') {
        return this.isDroppable.groups;
      }
      return true;
    },
    /*
     *  onChange is fired when a items drops in a draggable container (which changes the configuration object).
     *  The available and used chart types are set to 'pie' when there is no time relevance or 'bar' (default).
     *  Dispatches the 'getChartData' action, when there is one population and one feature selected.
     *
     */
    onChange() {
      this.$store.dispatch('resetDropBorders');
      // this.$store.commit('updateCurrentChartOptions');
      if (this.$store.getters.hasPropertyFeature && !this.$store.getters.hasActionFeature) {
        this.$store.commit('setSubTitle', this.buildPropertyChartSubTitle());
        this.$store.commit('setTitle', this.buildChartTitle());
        this.$store.dispatch('getPropertyChartData').then(() => {
          this.$store.commit('updateCurrentChartOptions');
          this.$store.commit('updateCurrentChartType');
        });
      } else if (this.$store.getters.hasActionFeature) {
        this.$store.dispatch('getActionChartData');
        this.$store.commit('setSubTitle', this.buildActionChartSubTitle());
        this.$store.commit('setTitle', this.buildChartTitle());
      }
    },
    /*
     *  When a population is dropped, onPopulationChange is called which triggers getFeatureValues from api
     */
    onPopulationChange() {
      this.$store.dispatch('getFeatureValues');
      this.onChange();
    },
    onFeatureChange(evt) {
      if (evt.added.element.type === 'property') {
        this.$store.commit('setChartType', 'bar');
      }
      this.onChange();
    },
    onFilterValueChange(values, type) {
      const ids = values.map((e) => e.id);
      this.$store.commit('updateFilterValues', { values: ids, type });
      this.onChange();
    },
    onGroupValueChange(values, type) {
      const ids = values.map((e) => e.id);
      this.$store.commit('updateGroupValues', { values: ids, type });
      this.onChange();
    },
    onGroupChange(evt) {
      this.$store.commit('addGroup', evt.added.element.name);
      this.onChange();
    },
    removeItem({ id, name }, list) {
      this.$store.commit('removeItem', { list, id, name });
      this.$store.commit('setSubTitle', this.buildActionChartSubTitle());
      this.$store.commit('setTitle', this.buildChartTitle());
      this.onChange();
      /* close values list after removing */
      if (list === 'filter') {
        this.showFilterFeatureValues[id] = false;
      } else if (list === 'group') {
        this.showGroupFeatureValues[id] = false;
      }
    },
    showTotal(val) {
      this.$store.commit('setShowTotalGroup', val);
      this.onChange();
    },
    /*
    * Title builder methods
    * TODO: move in store to make it callable from interval change component
    */
    buildChartTitle() {
      return `${this.$t('count')} ${this.$tc(this.usedPopulationsList[0].name, 0)}`;
    },
    buildActionChartSubTitle() {
      return this.usedFeaturesList.map((e) => ` ${this.$tc(e.name, 0)}`)
        .join(); // .concat(` pro ${this.$t(this.selectedInterval)}`);
    },
    buildPropertyChartSubTitle() {
      return `${this.$tc(this.usedPopulationsList[0].name, 0)} pro ${this.$tc(this.usedFeaturesList[0].name, 1)}`;
    },
    reset() {
      this.$store.dispatch('resetDatacenter');
    },
  },
};
