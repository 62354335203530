<template>
  <div class="relative h-full mb-3 text-gray-800 bg-white border-2 border-white" :class="{'border-drop': borderDrop}">
    <div class="absolute top-0 left-0 m-2">{{ label }}</div>
    <draggable
      :value="value"
      :id="id"
      :move="move"
      :group="group"
      :handle="disabled ? '.disabledClassDummy' : handle"
      @input="onInput"
      @start="onStart"
      @change="onChange"
      @end="onEnd"
      @drag="onDrag"
      @drop="onDrop"
      class="pt-10 min-height-drop"
      ghost-class="dnd-item"
      drag-class="dnd-item"
    >
      <div
        class="mx-2 text-white bg-gray-800"
        :class="{'cursor-move': !disabled }"
        v-for="item in value"
        :key="item.id"
      >
        <slot v-if="item" :item="item">
          <!-- Default -->
          <div class="flex flex-row items-center mb-2 text-white">
            <div v-if="itemLabelPlural" class="p-1 ml-2 mr-1"> {{ $tc(item.name, 0) }} </div>
            <div v-else-if="!itemLabelPlural" class="p-1 ml-2 mr-1"> {{ $tc(item.name, 1) }} </div>
            <div class="flex-none ml-auto mr-1 transform icon-position ">
              <button @click="onReset(item)" class="focus:outline-none">
                <Icon name="close" class="cursor-pointer focus:outline-none"/>
              </button>
            </div>
          </div>
        </slot>
      </div>
    </draggable>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    id: {
      required: false,
      type: String,
      default: '',
    },
    label: {
      required: false,
      type: String,
      default: '',
    },
    move: {
      required: false,
      type: Function,
    },
    borderDrop: {
      required: false,
      type: Boolean,
      default: false,
    },
    dragClass: {
      required: false,
      type: String,
      default: '',
    },
    ghostClass: {
      required: false,
      type: String,
      default: '',
    },
    group: {
      required: false,
      type: String,
      default: '',
    },
    handle: {
      required: false,
      type: String,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    itemLabelPlural: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onStart(evt) {
      this.$emit('start', evt);
    },
    onEnd() {
      this.$emit('end');
    },
    onChange(evt) {
      this.$emit('change', evt);
    },
    onInput(val) {
      this.$emit('input', val);
    },
    onReset(item) {
      this.$emit('reset', item);
    },
    onDrag() {
      this.$emit('drag');
    },
    onDrop() {
      this.$emit('drop');
    },
  },
};
</script>

<style scoped>
.dnd-item {
  background-color: theme('colors.primary.500');
}

.border-drop {
  border-color: theme('colors.primary.500');
}

.min-height-drop {
  min-height: 80px
}

.icon-position {
  line-height: 100%;
  --transform-translate-y: 0.1rem
}
</style>
