<template>
  <div class="py-1 pl-3 mb-2">
    <div :class="handle">
      <div class="flex flex-row items-center pr-3 text-white" :class="{'cursor-move': !disableDrag }">
        <div>{{ getName(i18nKey) }}</div>
        <div class="flex-none ml-auto -mr-2 transform icon-position">
          <button
            @click="toggleActive"
            class="transition-transform duration-300 focus:outline-none"
            :class="{'transform rotate-180': active}"
          >
            <Icon name="chevron-down" class="cursor-pointer focus:outline-none"/>
          </button>
          <button @click="remove" class="focus:outline-none">
            <Icon name="close" class="cursor-pointer focus:outline-none"/>
          </button>
        </div>
      </div>
    </div>
    <transition name="collapse">
      <div v-show="active" class="checkbox-collapse">
        <!-- Search -->
        <div class="pt-3 pr-3">
          <TextField v-model="searchFilter" :placeholder="$t('search')" class="text-gray-800 text-field-margin"/>
        </div>
        <div class="mr-2 scrollable">
          <!-- Buttons -->
          <div class="flex justify-between pr-3 mb-3 text-white">
            <button @click="selectAll" class="focus:outline-none checkbox-select__action">{{ $t('select_all') }}</button>
            <button @click="deselectAll" class="focus:outline-none checkbox-select__action">{{ $t('delete') }}</button>
          </div>
          <!-- Checkboxes -->
          <CheckBox
            v-if="showTotalCheckbox"
            @check="checkTotal()"
            @uncheck="uncheckTotal()"
          >
            <div class="text-base text-white"> {{ $t('total') }} </div>
          </CheckBox>
          <div v-for="value in valuesFiltered" :key="value.id">
            <CheckBox
              v-model="selectedValues"
              :native-value="value"
              @check="check(value.id)"
              @uncheck="uncheck(value.id)"
              class.native="mb-0"
            >
              <div class="text-base text-white">{{ value.name }}</div>
            </CheckBox>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      /**
       * Determines whether the dropdown is open or closed.
       */
      active: false,
      /**
       * Array holding all selected values.
       */
      selectedValues: this.selectedByDefault ? this.getUniqueValues(this.values) : [], // TODO: Remove when backend only sends unique ids.
      /**
       * Holding the current string the user is searching for.
       */
      searchFilter: '',
    };
  },
  props: {
    /**
     * The values to be displayed in the dropdown.
     */
    values: {
      type: Array,
      required: true,
    },
    /**
     * The i18n key to translate and adapt plural depending number of selected items.
     */
    i18nKey: {
      type: String,
      required: true,
    },
    /**
     * If set to true, all values are selected by default.
     */
    selectedByDefault: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If set to true, total-checkbox is displayed.
     */
    showTotal: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Use in combination with vue-draggable-class. Only used here to prevent dragging while marking text.
     */
    handle: {
      type: String,
      required: false,
      default: null,
    },
    disableDrag: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Get the number of selected fields.
     */
    numSelected() {
      return this.selectedValues.length;
    },
    showTotalCheckbox() {
      return this.numSelected > 1 && this.showTotal;
    },
    valuesFiltered() {
      const values = this.getUniqueValues(this.values); // TODO: Remove when backend only sends unique ids.
      if (this.searchFilter) {
        // return this.values.filter((value) => {
        return values.filter((value) => { // TODO: Remove when backend only sends unique ids.
          const valueToLower = value.name.toLowerCase()
            .trim();
          const searchFilterToLower = this.searchFilter.toLowerCase()
            .trim();
          return valueToLower.indexOf(searchFilterToLower) >= 0;
        });
      }
      return values; // TODO: Remove when backend only sends unique ids.
    },
  },
  methods: {
    // TODO: Remove when backend only sends unique ids.
    getUniqueValues(values) {
      const ids = values.map((e) => e.id);
      const uniqueIds = [...new Set(ids)];
      const res = [];
      uniqueIds.forEach((id) => {
        res.push(values.find((e) => e.id === id));
      });
      return res;
    },
    /**
     * Toggle the active state.
     * Active state determines whether the dropdown is open or closed.
     */
    toggleActive() {
      this.active = !this.active;
    },
    /**
     * Emit 'remove' event.
     */
    remove() {
      this.$emit('remove');
    },
    /**
     * Add value to array of selected values and
     * emit new selected values array to parent component.
     */
    check() {
      this.$emit('check', this.selectedValues);
    },
    /**
     * Remove value from array of selected values and
     * emit new selected values array to parent component.
     */
    uncheck() {
      this.$emit('uncheck', this.selectedValues);
    },
    /**
     * Emit 'uncheck' event.
     */
    checkTotal() {
      this.$emit('check-total');
    },
    /**
     * Emit 'check-total' event.
     */
    uncheckTotal() {
      this.$emit('uncheck-total');
    },
    selectAll() {
      // this.selectedValues = [...this.values]; // TODO: Remove when backend only sends unique ids.
      this.selectedValues = [...this.getUniqueValues(this.values)];
      this.$emit('select-all', this.selectedValues);
    },
    deselectAll() {
      this.selectedValues = [];
      this.$emit('deselect-all', this.selectedValues);
    },
    /**
     * Get the translated version of the value depending on the number of selected items.
     * If the translation is longer than 14, the short form is displayed.
     * @param i18nKey
     * @returns String
     */
    getName(i18nKey) {
      return this.$t('items_chosen', {
        n: this.numSelected,
        name: this.$tc(i18nKey, 0), // Always use plural
      });
    },
  },
};
</script>

<style scoped lang="scss">
.icon-position {
  line-height: 100%;
  --transform-translate-y: 0.1rem
}

.scrollable {
  overflow: auto;
  max-height: 150px;
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #FE5000;
    border-radius: 5px;
  }
}

.checkbox-select__action {
  &:hover {
    color: theme('colors.primary.500');
  }
}

.checkbox-collapse {
  max-height: 200px;
  overflow: hidden;
  transition: max-height .3s;
}

.collapse-enter, .collapse-leave-to {
  max-height: 0;
}

/* TODO: Add possibility to change margin in TextField component. */
.text-field-margin {
  margin-bottom: .5rem !important;
}

</style>
