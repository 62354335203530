<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 139 84.75">
    <g id="Gruppe_436" data-name="Gruppe 436" transform="translate(-1050.999 -442.25)">
      <line id="Linie_194" data-name="Linie 194" x2="139" transform="translate(1050.999 442.5)" fill="none" stroke="#8e9295" stroke-width="0.5"/>
      <line id="Linie_194-2" data-name="Linie 194" x2="139" transform="translate(1050.999 463.5)" fill="none" stroke="#8e9295" stroke-width="0.5"/>
      <line id="Linie_194-3" data-name="Linie 194" x2="139" transform="translate(1050.999 484.5)" fill="none" stroke="#8e9295" stroke-width="0.5"/>
      <line id="Linie_194-4" data-name="Linie 194" x2="139" transform="translate(1050.999 505.5)" fill="none" stroke="#8e9295" stroke-width="0.5"/>
      <path id="Pfad_1001" data-name="Pfad 1001" d="M0,0H139" transform="translate(1050.999 526.5)" fill="none" stroke="#8e9295" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>
