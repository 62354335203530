<template>
  <div class="w-24">
    <SelectField2
      v-model="selectedInterval"
      :options="availableIntervals"
      :label="$t('interval')"
      :disabled="hasPropertyFeature && !hasActionFeature"
      @change="onChange"
    />
  </div>
</template>

<script>
export default {
  computed: {
    selectedInterval: {
      get() {
        return this.$store.getters.chartConfig.interval.type;
      },
      set(data) {
        this.$store.commit('setIntervalType', data);
      },
    },
    availableIntervals() {
      const intervals = this.$store.state.datacenter.availableIntervals;
      return intervals.map((interval) => ({ text: this.$t(interval), value: interval }));
    },
    hasPropertyFeature() {
      return this.$store.getters.hasPropertyFeature;
    },
    hasActionFeature() {
      return this.$store.getters.hasActionFeature;
    },
  },
  mounted() {
    this.$store.commit('setIntervalType', this.selectedInterval);
  },
  methods: {
    onChange() {
      this.$store.commit('setIntervalType', this.selectedInterval);
      if (this.hasActionFeature) {
        this.$store.dispatch('getActionChartData');
      } else if (this.hasPropertyFeature) {
        this.$store.dispatch('getPropertyChartData');
      }
    },
  },
};
</script>
