<template>
  <Modal :value="value" @input="onInput" modal-class="max-w-full" class="modal" close-button>
    <div class="relative p-10 text-gray-800">
      <div class="p-1 sm:p-5 lg:py-10 lg:px-40">
        <!-- Text -->
        <div class="mb-8 text-center">
          <p class="text-4xl font-light">{{ $t('sample_data_title') }}</p>
          <p class="text-xl text-primary-500">{{ $t('sample_data_sub_title') }}</p>
        </div>
        <!-- Tiles -->
        <div class="flex flex-row justify-center cursor-pointer">
          <div
            class="flex flex-col justify-between p-5 mb-5 mr-5 shadow tile"
            @click="selectSampleData"
            :class="{'active': sampleDataSelected}"
          >
            <div class="mb-5">
              <b>{{ $t('sample_data_load_heading_01') }}</b>
              <p>{{ $t('sample_data_description_01') }}</p>
            </div>
            <div>
              <SvgSampleChartData/>
            </div>
          </div>
          <div
            @click="selectSampleDataEmpty"
            class="flex flex-col justify-between p-5 mb-5 shadow tile"
            :class="{'active': sampleDataSelectedEmpty}"
          >
            <div class="mb-5">
              <b>{{ $t('sample_data_load_heading_02') }}</b>
              <p>{{ $t('sample_data_description_02') }}</p>
            </div>
            <div class="flex align-baseline">
              <SvgSampleChartDataEmpty/>
            </div>
          </div>
        </div>
        <!-- Checkbox -->
        <div class="flex justify-end">
          <CheckBox v-model="hideModalInTheFuture">{{ $t('dont_show_in_the_future') }}</CheckBox>
        </div>
      </div>
      <!-- Confirm button -->
      <Button @click="onConfirm" primary class="ml-auto" :disabled="!tileSelected">{{ $t('approve') }}</Button>
    </div>
  </Modal>
</template>

<script>
import SvgSampleChartData from '@/ui/views/DataCenter/DataCenterChart/components/base/SvgSampleChartData.vue';
import SvgSampleChartDataEmpty from '@/ui/views/DataCenter/DataCenterChart/components/base/SvgSampleChartDataEmpty.vue';

export default {
  components: { SvgSampleChartDataEmpty, SvgSampleChartData },
  data() {
    return {
      hideModalInTheFuture: false,
      sampleDataSelected: false,
      sampleDataSelectedEmpty: false,
    };
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    tileSelected() {
      return this.sampleDataSelected || this.sampleDataSelectedEmpty;
    },
  },
  methods: {
    selectSampleData() {
      this.sampleDataSelected = true;
      this.sampleDataSelectedEmpty = false;
    },
    selectSampleDataEmpty() {
      this.sampleDataSelected = false;
      this.sampleDataSelectedEmpty = true;
    },
    onConfirm() {
      if (this.hideModalInTheFuture) {
        localStorage.setItem('hide_entry_modal', true);
      }
      this.$emit('confirm', this.sampleDataSelected);
    },
    onInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped lang="scss">
.tile {
  width: 150px;
  height: auto;
  border: 2px solid transparent;
  transition: .2s;

  &:hover, &.active {
    border-color: #FE5000;
  }

  @media (min-width: 600px) {
    width: 250px;
  }
  @media (min-width: 1024px) {
    width: 400px;
    height: 400px;
  }
}

.modal {
  // Stay above footer
  z-index: 51;
}

.close-button {
  right: 2rem;
  position: absolute;
  top: 0;
  cursor: pointer;
  @media (min-width: 1024px) {
    right: 1rem;
  }
}
</style>
