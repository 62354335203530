<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 139 96">
    <g id="Gruppe_435" data-name="Gruppe 435" transform="translate(-745.5 -432)">
      <line id="Linie_194" data-name="Linie 194" x2="139" transform="translate(745.5 443.5)" fill="none" stroke="#8e9295" stroke-width="0.5"/>
      <line id="Linie_194-2" data-name="Linie 194" x2="139" transform="translate(745.5 464.5)" fill="none" stroke="#8e9295" stroke-width="0.5"/>
      <line id="Linie_194-3" data-name="Linie 194" x2="139" transform="translate(745.5 485.5)" fill="none" stroke="#8e9295" stroke-width="0.5"/>
      <line id="Linie_194-4" data-name="Linie 194" x2="139" transform="translate(745.5 506.5)" fill="none" stroke="#8e9295" stroke-width="0.5"/>
      <line id="Linie_194-5" data-name="Linie 194" x2="139" transform="translate(745.5 527.5)" fill="none" stroke="#8e9295" stroke-width="1"/>
      <g id="Gruppe_432" data-name="Gruppe 432" transform="translate(-287 -29.925)">
        <g id="Rechteck_1232" data-name="Rechteck 1232" transform="translate(1041 495.925)" fill="#36a9d0" stroke="#36a9d0" stroke-width="1">
          <rect width="18" height="61" stroke="none"/>
          <rect x="0.5" y="0.5" width="17" height="60" fill="none"/>
        </g>
        <g id="Rechteck_1233" data-name="Rechteck 1233" transform="translate(1067 461.925)" fill="#4cc0ad" stroke="#4cc0ad" stroke-width="1">
          <rect width="18" height="95" stroke="none"/>
          <rect x="0.5" y="0.5" width="17" height="94" fill="none"/>
        </g>
        <g id="Rechteck_1234" data-name="Rechteck 1234" transform="translate(1093 513.925)" fill="#36a9d0" stroke="#36a9d0" stroke-width="1">
          <rect width="18" height="43" stroke="none"/>
          <rect x="0.5" y="0.5" width="17" height="42" fill="none"/>
        </g>
        <g id="Rechteck_1235" data-name="Rechteck 1235" transform="translate(1119 545.925)" fill="#4cc0ad" stroke="#4cc0ad" stroke-width="1">
          <rect width="18" height="11" stroke="none"/>
          <rect x="0.5" y="0.5" width="17" height="10" fill="none"/>
        </g>
        <g id="Rechteck_1236" data-name="Rechteck 1236" transform="translate(1145 495.925)" fill="#36a9d0" stroke="#36a9d0" stroke-width="1">
          <rect width="18" height="61" stroke="none"/>
          <rect x="0.5" y="0.5" width="17" height="60" fill="none"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>
