<template>
  <div class="bg-gray-200 ">
    <!-- Populations -->
    <DropfieldItem
      v-model="usedPopulationsList"
      :label="$t('baseData')"
      :border-drop="isDroppable.population"
      :move="checkMove"
      :disabled="true"
      @start="isDroppable.population = true"
      @end="resetBorders"
      @change="onPopulationChange"
      @reset="reset()"
      id="usedPopulationsList"
      group="populations"
      item-label-plural
    />
    <!-- Features -->
    <DropfieldItem
      v-model="usedFeaturesList"
      :label="$t('measuredValue')"
      :border-drop="isDroppable.features"
      :move="checkMove"
      :disabled="true"
      @start="onStart"
      @end="resetBorders"
      @change="onFeatureChange"
      @reset="removeItem($event, 'feature')"
      id="usedFeaturesList"
      group="features"
    >
      <template v-if="usedFeatureIsProperty" v-slot="{ item }">
        <CheckboxSelect
          :key="item.id"
          :i18n-key="item.name"
          :values="item.values"
          :disable-drag="true"
          :showTotal="true"
          @check="onGroupValueChange($event, item.name)"
          @uncheck="onGroupValueChange($event, item.name)"
          @select-all="onGroupValueChange($event, item.name)"
          @deselect-all="onGroupValueChange($event, item.name)"
          @remove="removeItem(item, 'feature')"
          @check-total="showTotal(true);"
          @uncheck-total="showTotal(false);"
        />
      </template>
    </DropfieldItem>
    <!-- Filters -->
    <DropfieldItem
      v-model="usedFiltersList"
      :label="$t('filters')"
      :border-drop="isDroppable.filters"
      :move="checkMove"
      :disabled="true"
      @start="onStart"
      @end="resetBorders"
      @change="onChange"
      @reset="removeItem($event, 'filter')"
      id="usedFiltersList"
      group="features"
    >
      <template v-slot="{ item }">
        <CheckboxSelect
          :key="item.id"
          :i18n-key="item.name"
          :values="item.values"
          :selected-by-default="true"
          :disable-drag="true"
          @check="onFilterValueChange($event, item.name)"
          @uncheck="onFilterValueChange($event, item.name)"
          @select-all="onFilterValueChange($event, item.name)"
          @deselect-all="onFilterValueChange($event, item.name)"
          @remove="removeItem(item, 'filter')"
        />
      </template>
    </DropfieldItem>
    <!-- Groups -->
    <DropfieldItem
      v-model="usedGroupsList"
      :label="$t('grouping')"
      :border-drop="isDroppable.groups"
      :move="checkMove"
      :disabled="true"
      @start="onStart"
      @end="resetBorders"
      @change="onChange"
      @reset="removeItem($event, 'group')"
      @drag="showFeatureValues = false"
      @drop="showFeatureValues = true"
      id="usedGroupsList"
      group="features"
    >
      <template v-slot="{ item }">
        <CheckboxSelect
          :key="item.id"
          :showTotal="true"
          :i18n-key="item.name"
          :disable-drag="true"
          :values="item.values"
          @check="onGroupValueChange($event, item.name)"
          @uncheck="onGroupValueChange($event, item.name)"
          @select-all="onGroupValueChange($event, item.name)"
          @deselect-all="onGroupValueChange($event, item.name)"
          @remove="removeItem(item, 'group')"
          @check-total="showTotal(true);"
          @uncheck-total="showTotal(false);"
        />
      </template>
    </DropfieldItem>
  </div>
</template>

<script>

import dndHelper from '@/mixins/dndHelper';
import CheckboxSelect from '@/ui/components/CheckboxSelect.vue';
import DropfieldItem from '@/ui/views/DataCenter/DataCenterChart/components/DropfieldItem.vue';

export default {
  components: {
    DropfieldItem,
    CheckboxSelect,
  },
  mixins: [dndHelper],
  data() {
    return {
      drag: null,
      showFilterFeatureValues: [],
      showGroupFeatureValues: [],
    };
  },
  methods: {
    toggleFilterFeatureValues(id) {
      if (this.showFilterFeatureValues[id] == null) {
        this.$set(this.showFilterFeatureValues, id, true);
      } else {
        this.$set(this.showFilterFeatureValues, id, !this.showFilterFeatureValues[id]);
      }
    },
    toggleGroupFeatureValues(id) {
      if (this.showGroupFeatureValues[id] == null) {
        this.$set(this.showGroupFeatureValues, id, true);
      } else {
        this.$set(this.showGroupFeatureValues, id, !this.showGroupFeatureValues[id]);
      }
    },
    onCheck(values) {
      console.log(values);
    },
    onUncheck(values) {
      console.log(values);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
